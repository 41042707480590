/**
 * @description Get the previous epoch based on the {@link lastWorkTimestamp}
 */
export function useLastEpoch() {
  const { lastWorkTimestamp } = toRefs(useDOBStore())

  /**
   * @description Get the previous epoch based on the {@link lastWorkTimestamp}
   * @param lastEpochStartTimestamp - The last epoch start timestamp in seconds
   * @returns The previous epoch start and end timestamp in seconds
   */
  function getPreviousEpoch(lastEpochStartTimestamp: string) {
    const start = new Date(Number(lastEpochStartTimestamp) * 1000)

    // End of the epoch (last work timestamp + 1 day) 24-hour epoch
    const end = new Date(Number(lastEpochStartTimestamp) * 1000)
    end.setDate(end.getDate() + 1)

    // if the epoch is current epoch that has not finished, we need to get the previous epoch which was one day before
    if (new Date().getTime() < end.getTime()) {
      start.setDate(start.getDate() - 1)
      end.setDate(end.getDate() - 1)
    }

    return {
      start: start.getTime() / 1000,
      end: end.getTime() / 1000,
    }
  }

  const lastEpoch = computed(() => {
    return getPreviousEpoch(lastWorkTimestamp.value)
  })

  return {
    lastEpoch,
  }
}
