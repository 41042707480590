import { Big } from 'big.js'
import {
  type Transaction,
  getTransactionList,
  type Bullet,
} from '@deorderbook/sdk'
import { NativeToken } from '@base/types/tokens'
import {
  optionBulletToRewardRatio,
  optionEntryFeeRatio,
} from '@deorderbook/sdk/ethereum/option'
import { OptionType } from '@base/types/options'

function fetchOptionEntryFeeRatio(optionAddress: string) {
  return optionEntryFeeRatio(optionAddress)
    .then((res: number) => (res ? Number(res) / 10000 : 0))
    .catch((err) => {
      console.error(err)
      return 0
    })
}

function fetchOptionBulletToRewardRatio(optionAddress: string) {
  return optionBulletToRewardRatio(optionAddress)
    .then((res: number) => (res ? Number(res) / 10000 : 0))
    .catch((err) => {
      console.error(err)
      return 0
    })
}

// eslint-disable-next-line max-lines-per-function
export function useBulletReward() {
  const { address } = useAccount()
  const { lastEpoch } = useLastEpoch()
  const { divDecimals } = useTokens()
  const { getBulletPrice } = useTokensStore()
  const {
    options,
    getOptionBy,
    isLoading: isOptionsLoading,
    refresh: refreshOptions,
  } = useOptions()
  const { state: distributionFees } = useDistributionFee()
  const {
    bullets: allBullets,
    loading: isBulletsLoading,
    actionRefreshBullets: refreshBullets,
  } = toRefs(useBulletsStore())
  const {
    dobContractInfo,
    userInfo,
    lastWorkTimestamp,
    dobContractInfoLoading,
    actionRefreshDOBContractInfo: refreshDOBContractInfo,
    actionRefreshUserDOBInfo: refreshUserDOBInfo,
  } = toRefs(useDOBStore())
  const {
    lastDeliverStartBlock,
    lastDeliverEndBlock,
    userClaimBlock,
    loading: isDobRewardsLoading,
    actionRefreshItems: refreshDobRewards,
  } = toRefs(useDOBRewardStore())

  const loading = computed(() => {
    return (
      isOptionsLoading.value ||
      isBulletsLoading.value ||
      dobContractInfoLoading.value ||
      isDobRewardsLoading.value
    )
  })

  const refresh = async () => {
    await Promise.all([
      refreshOptions(),
      refreshBullets.value(),
      refreshDOBContractInfo.value(),
      refreshUserDOBInfo.value(),
      refreshDobRewards.value(),
    ])
  }

  /**
   * @description Total number of DOB staked by all users in the previous cycle
   * {@link https://github.com/DeOrderBook/v1-core/blob/3c82a039003798fa801cc602714456e7455f81dd/contracts/staking/DOBStakingPool.sol#L807}
   */
  const lockAmount = computed(() => {
    let lockAmount = '0'

    if (
      Number(userInfo.value.currentStakingAmount) >=
        Number(dobContractInfo.value.bulletRewardThreshold) &&
      Number(userInfo.value.stakingAmountUpdateBlockHeight) >
        Number(lastDeliverStartBlock.value) &&
      Number(userInfo.value.stakingAmountUpdateBlockHeight) <=
        Number(lastDeliverEndBlock.value)
    ) {
      lockAmount = userInfo.value.currentStakingAmount.toString()
    }

    if (
      Number(userInfo.value.claimStakingAmount) >=
        Number(dobContractInfo.value.bulletRewardThreshold) &&
      Number(userInfo.value.claimAmountUpdateBlockHeight) >
        Number(lastDeliverStartBlock.value) &&
      Number(userInfo.value.claimAmountUpdateBlockHeight) <=
        Number(lastDeliverEndBlock.value)
    ) {
      lockAmount = userInfo.value.claimStakingAmount.toString()
    }
    return lockAmount
  })

  const lockShare = computed(() => {
    if (Big(totalLockAmountLastEpoch.value).lte(0)) return 0
    return Big(lockAmount.value).div(totalLockAmountLastEpoch.value).toNumber()
  })

  const getExerciseEndTimestamp = (exerciseTimestamp: string) => {
    return Number(exerciseTimestamp + '000') + 24 * 60 * 60 * 1000 // 24 hours in milliseconds
  }

  const optionIds = computed(() => {
    return options.value.map((option) => option.id)
  })

  /** Bullets that have not ended the exercise period */
  const currentEpochBullets = computed(() => {
    if (!allBullets.value?.length) return [] as Bullet[]
    return allBullets.value.filter(
      (item: Bullet) =>
        optionIds.value.includes(item.optionId) &&
        Date.now() < getExerciseEndTimestamp(item.exerciseTimestamp),
    ) as Bullet[]
  })

  /** Get all deorder transaction records in the current cycle */
  const allDeorderTxsCurrentEpoch = computedAsync(async () => {
    return await getTransactionList({
      where: {
        action_starts_with: 'DeOrder',
        timestamp_gt: lastWorkTimestamp.value,
      },
    })
  }, [])

  const allLockTxsLastEpoch = computedAsync(async () => {
    const txsFromLastEpoch = await getTransactionList({
      where: {
        action: 'Lock',
        timestamp_gt: lastEpoch.value.start,
        timestamp_lt: lastEpoch.value.end,
      },
    })
    return txsFromLastEpoch
  }, [])

  const totalLockAmountLastEpoch = computed(() => {
    return allLockTxsLastEpoch.value
      .reduce((sum, tx) => {
        const outToken = tx.outTokens.find(
          (item) => item.token === NativeToken.DOB,
        )
        const lockAmount = Big(outToken?.amount ?? '0')
        return sum.add(lockAmount)
      }, Big(0))
      .toFixed()
  })

  const userLockAmountLastEpoch = computed(() => {
    return allLockTxsLastEpoch.value
      .filter((tx) => tx.account === address.value)
      .reduce((sum, tx) => {
        const outToken = tx.outTokens.find(
          (item) => item.token === NativeToken.DOB,
        )
        const lockAmount = Big(outToken?.amount ?? '0')
        return sum.add(lockAmount)
      }, Big(0))
      .toFixed()
  })

  const allDeorderTxsLastEpoch = computedAsync(async () => {
    return await getTransactionList({
      where: {
        action_starts_with: 'DeOrder',
        timestamp_gt: lastEpoch.value.start,
        timestamp_lt: lastEpoch.value.end,
      },
    })
  }, [])

  const activeOptions = computed(() => {
    return options.value.filter(
      (option) => +option.exerciseTimestamp > Date.now() / 1000,
    )
  })

  const defaultEntryFeeRatio = computed(() => {
    return Big(distributionFees.value.entryFee || 0).toNumber()
  })

  const defaultBulletToRewardRatio = computed(() => {
    return Big(distributionFees.value.bulletToReward || 0).toNumber()
  })

  /**
   * @description Get the entry fee ratio and bullet to reward ratio of the option in the last epoch. Uses {@link optionFeesLastEpoch}
   * @param deorder - deorder transaction
   * @returns entryFeeRatio and bulletToRewardRatio
   */
  const getRatiosByDeOrderLastEpoch = (deorder: Transaction) => {
    let entryFeeRatio = defaultEntryFeeRatio.value
    let bulletToRewardRatio = defaultBulletToRewardRatio.value

    const optionFees = optionFeesLastEpoch.value.find(
      (
        x:
          | {
              exerciseTimestamp: string | null
              strikePrice: string | null
              optionType: '0' | '1' | null
              entryFeeRatio: number
              bulletToRewardRatio: number
            }
          | undefined,
      ) =>
        deorder.optionType === x?.optionType &&
        deorder.strikePrice === x?.strikePrice &&
        deorder.exerciseTimestamp === x?.exerciseTimestamp,
    )
    const optionEntryFee = optionFees?.entryFeeRatio
    const optionBulletToRewardRatio = optionFees?.bulletToRewardRatio
    if (!isNaN(Number(optionEntryFee))) {
      entryFeeRatio = Number(optionEntryFee)
    }
    if (!isNaN(Number(optionBulletToRewardRatio))) {
      bulletToRewardRatio = Number(optionBulletToRewardRatio) * 100
    }

    return {
      entryFeeRatio,
      bulletToRewardRatio,
    }
  }

  const claimableBullets = computedAsync(async () => {
    if (
      activeOptions.value.length === 0 ||
      allDeorderTxsLastEpoch.value.length === 0
    )
      return []

    const bulletRewards = []
    for (const option of activeOptions.value) {
      const allDeordersOfThisOption = allDeorderTxsLastEpoch.value.filter(
        (deorder) =>
          deorder.optionType === option.optionType &&
          deorder.strikePrice === option.strikePrice &&
          deorder.exerciseTimestamp === option.exerciseTimestamp,
      )
      const rewardAmount = allDeordersOfThisOption.reduce(
        (accumulator, deorder) => {
          const { entryFeeRatio, bulletToRewardRatio } =
            getRatiosByDeOrderLastEpoch(deorder)
          const outToken = deorder.outTokens.find((item) =>
            item.token?.endsWith('HODL'),
          ) // deorder amount
          const bulletAmount = Big(outToken?.amount || '0')
            .mul(bulletToRewardRatio)
            .mul(lockShare.value)
            .mul(1 - entryFeeRatio)
          return Big(accumulator).add(bulletAmount).toNumber()
        },
        0,
      )
      const token =
        String(option.optionType) === '0' ? 'sellTokenBULLET' : 'uBULLET'
      const bulletPrice = await getBulletPrice({
        exerciseTimestamp: option.exerciseTimestamp,
        strikePrice: divStrikePrice(option.strikePrice),
        type: token,
      })

      bulletRewards.push({
        ...option,
        rewardAmount,
        bulletPrice,
      })
    }
    return bulletRewards.filter((x) => Big(x.rewardAmount).gt(0))
  }, [])

  const myTotalClaimableBulletAmount = computed(() => {
    return claimableBullets.value
      .reduce((sum, current) => {
        return sum.add(
          divDecimals(current.rewardAmount.toString(), 'bullet').value,
        )
      }, Big(0))
      .toFixed()
  })

  const myTotalClaimableBulletUSD = computed(() => {
    return claimableBullets.value
      .reduce((sum, current) => {
        return sum.add(
          Big(
            divDecimals(current.rewardAmount.toString(), 'bullet').value,
          ).times(current.bulletPrice),
        )
      }, Big(0))
      .toFixed()
  })

  const getOptionFeesFromDeOrderTxs = async (deorderTxs: Transaction[]) => {
    const data = []
    for (const tx of deorderTxs) {
      const option = getOptionBy(options, {
        optionType: tx.optionType!,
        exerciseTimestamp: tx.exerciseTimestamp!,
        strikePrice: tx.strikePrice!,
      })
      if (!option.value || option.value.address === undefined) continue
      // FIXME: refactor direct requests
      const [entryFeeRatio, bulletToRewardRatio] = await Promise.all([
        fetchOptionEntryFeeRatio(option.value.address),
        fetchOptionBulletToRewardRatio(option.value.address),
      ])
      data.push({
        exerciseTimestamp: tx.exerciseTimestamp,
        strikePrice: tx.strikePrice,
        optionType: tx.optionType,
        entryFeeRatio: entryFeeRatio,
        bulletToRewardRatio: bulletToRewardRatio,
      })
    }
    return data
  }

  /**
   * @description Get the entry fee ratio and bullet to reward ratio of the option in the current epoch. Uses {@link allDeorderTxsCurrentEpoch} list
   */
  const optionFeesCurrentEpoch = computedAsync(async () => {
    const deorderTxs = allDeorderTxsCurrentEpoch.value
    const res = await getOptionFeesFromDeOrderTxs(deorderTxs)
    return res
  }, [])

  /**
   * @description Get the entry fee ratio and bullet to reward ratio of the option in the last epoch. Uses {@link allDeorderTxsLastEpoch} list
   */
  const optionFeesLastEpoch = computedAsync(async () => {
    const deorderTxs = allDeorderTxsLastEpoch.value
    const res = await getOptionFeesFromDeOrderTxs(deorderTxs)
    return res
  }, [])

  /** Get the number of bullets for all options in the current cycle */
  const optionsBulletsCurrentEpoch = computed(() => {
    const list = [] as { bullet: string; amount: string }[]
    allDeorderTxsCurrentEpoch.value.forEach((deorder) => {
      const option = getOptionBy(options, {
        optionType: deorder.optionType!,
        exerciseTimestamp: deorder.exerciseTimestamp!,
        strikePrice: deorder.strikePrice!,
      })
      if (!option.value || option.value?.address === undefined) return undefined
      const bulletToRewardRatio =
        optionFeesCurrentEpoch.value.find(
          (
            x:
              | {
                  exerciseTimestamp: string | null
                  strikePrice: string | null
                  optionType: '0' | '1' | null
                  entryFeeRatio: number
                  bulletToRewardRatio: number
                }
              | undefined,
          ) =>
            x?.optionType === option.value?.optionType &&
            x?.strikePrice === option.value?.strikePrice &&
            x?.exerciseTimestamp === option.value?.exerciseTimestamp,
        )?.bulletToRewardRatio ?? defaultBulletToRewardRatio.value

      const bulletAmount = Big(bulletToRewardRatio || 0)
        .times(100)
        .times(
          deorder.inTokens?.find((item) => item.token?.endsWith('SNIPER'))
            ?.amount || '0',
        )
        .toFixed()

      const existBulletIndex = list.findIndex(
        (x) => x.bullet === option.value?.bullet,
      )
      // If there are the same bullets, add up the bullet amount
      if (existBulletIndex !== -1) {
        list[existBulletIndex].amount = Big(list[existBulletIndex].amount)
          .plus(bulletAmount)
          .toFixed()
      } else {
        list.push({
          bullet: option.value?.bullet,
          amount: bulletAmount,
        })
      }
    })
    return list
  })

  /** Calculate the potential bullet rewards for the day */
  const expectedRewardBullets = computedAsync(async () => {
    const todayUserStaking = userInfo.value.dailyStakingAmount
    const totalDailyLockDOB = dobContractInfo.value.totalDailyLockDOB
    const minLock = dobContractInfo.value.bulletRewardThreshold

    const share =
      Big(totalDailyLockDOB).lte(0) || Big(todayUserStaking).lt(minLock)
        ? 0
        : Big(todayUserStaking).div(totalDailyLockDOB).toFixed()
    const res = []
    for (const x of currentEpochBullets.value) {
      const totalBalance =
        optionsBulletsCurrentEpoch.value.find(
          (y: { bullet: string; amount: string }) => y?.bullet === x.id,
        )?.amount || '0'

      const myBullet = Big(share).times(totalBalance).toFixed()

      const token =
        String(x.optionType) === OptionType.SELL ? 'sellTokenBULLET' : 'uBULLET'
      const bulletPrice = await getBulletPrice({
        exerciseTimestamp: x.exerciseTimestamp,
        strikePrice: divStrikePrice(x.strikePrice),
        type: token,
      })

      res.push({
        ...x,
        totalBalance,
        myBullet,
        bulletPrice,
      })
    }
    return res
  }, [])

  /** The dollar value of all bullets issued today */
  const bulletsTotalUSD = computed(() => {
    return expectedRewardBullets.value
      .reduce((sum, current) => {
        return sum.add(
          Big(
            divDecimals(current.totalBalance.toString(), 'bullet').value,
          ).times(current.bulletPrice),
        )
      }, Big(0))
      .toFixed()
  })

  /** The dollar value of bullets the current user may get today */
  const myTotalUSD = computed(() => {
    return expectedRewardBullets.value
      .reduce((sum, current) => {
        return sum.plus(
          Big(divDecimals(current.myBullet, 'bullet').value).times(
            current.bulletPrice,
          ),
        )
      }, Big(0))
      .toFixed()
  })

  // Whether the user can claim the rewards from the previous period
  const isRewardClaimable = computed(() => {
    if (Number(userClaimBlock.value) > Number(lastDeliverEndBlock.value))
      return false
    return Big(lockAmount.value).gt(0) && claimableBullets.value.length > 0
  })

  return {
    // originData,
    myTotalUSD,
    bulletsTotalUSD,
    claimableBullets,
    myTotalClaimableBulletAmount,
    myTotalClaimableBulletUSD,
    expectedRewardBullets,
    isRewardClaimable,
    userLockAmountLastEpoch,
    totalLockAmountLastEpoch,
    loading,
    refresh,
    allDeorderTxsLastEpoch,
  }
}
